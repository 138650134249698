/**
 * Initializes single article functionality including scrolling effects.
 * @author Dreamers of Day
 */
function articleSingle3() {
    // Define the hooks used throughout the function for element selection.
    const hooks = {
        heading: 'js-title',
        articleContent: 'js-article-content',
        sticky: 'js-sticky',
        active: 'is-active',
    };

    // Retrieve the main article content element.
    const articleContent = document.querySelector(`.${hooks.articleContent}`);
    if (!articleContent) {
        return;
    }

    // Retrieve all subheadings within the article content.
    const contentHeadings = articleContent.querySelectorAll('h2, h3, h4');

    // Process each heading to set sanitized IDs and add necessary classes.
    contentHeadings.forEach((singleHeading) => {
        const title = singleHeading.innerText.trim();
        const sanitizedTitle = title
            .toLowerCase()
            .replace(/[.\-_/:]/gi, '')
            .replace(/\s/gi, '-');
        singleHeading.setAttribute('id', sanitizedTitle);
        singleHeading.classList.add(hooks.heading);
    });

    const headings = document.querySelectorAll(`.${hooks.heading}`);

    // If no headings are found, exit the function early.
    if (headings.length === 0) {
        return;
    }

    // Initialize IntersectionObserver to handle scroll-based activations.
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                const id = entry.target.getAttribute('id');
                const linkElement = document.querySelector(`[href="#${id}"]`);

                if (entry.intersectionRatio > 0) {
                    linkElement?.parentElement.classList.add(hooks.active);
                } else {
                    linkElement?.parentElement.classList.remove(hooks.active);
                }
            });
        },
        { threshold: [0.1] },
    );

    // Observe all headings to track their visibility on scroll.
    headings.forEach((heading) => observer.observe(heading));
}

export default articleSingle3;
