import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SplitText from '../vendors/gsap-splittext/splittext';

/**
 * Applies animation effects to split text elements on scroll.
 * @function letterEffects
 */
const letterEffects = () => {
    gsap.registerPlugin(ScrollTrigger, SplitText);

    // Selecting elements to animate
    const elements = document.querySelectorAll('.js-anim-split-text');

    // Checking if elements exist
    if (elements.length) {
        // Looping through each element
        elements.forEach((item) => {
            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: item,
                    end: 'bottom center',
                },
            });

            // Fade in animation
            tl.fromTo(
                item,
                { autoAlpha: 0 },
                { duration: 0.1, autoAlpha: 1, ease: 'power4.inOut' },
                '<',
            );

            // Splitting the text into individual letters or words
            const elementSplitted = new SplitText(item, {
                tag: 'span',
                type: 'chars,words',
                wordsClass: 'overflow-hidden leading-[1.15]',
            });

            const elementWords = elementSplitted.chars;

            // Animating each letter or word
            tl.from(elementWords, {
                duration: 1.4,
                yPercent: 100,
                ease: 'power4.inOut',
                stagger: {
                    amount: 0.4,
                },
            });
        });
    }
};

export default letterEffects;
