import { gsap } from 'gsap';

/**
 * Manages the display and behavior of a cookie consent banner.
 * Displays the banner if cookies are not yet accepted, handles user actions
 * to accept or reject cookies, and saves the user's choice in cookies.
 *
 * @function initCookieBanner
 * @exports initCookieBanner
 * @author Dreamers
 */
function initCookieBanner() {
    const bannerElement = document.querySelector('.a-cookie-banner');
    const acceptButtonElement = bannerElement?.querySelector(
        '.a-cookie-banner__button-accept',
    );
    const rejectButtonElement = bannerElement?.querySelector(
        '.a-cookie-banner__button-reject',
    );

    // Helper: Set a cookie
    const setCookie = (name, value, days) => {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/`;
    };

    // Helper: Get a cookie
    const getCookie = (name) => {
        const cookies = document.cookie.split('; ');
        for (const cookie of cookies) {
            const [key, value] = cookie.split('=');
            if (key === name) {
                return value;
            }
        }
        return null;
    };

    if (!bannerElement || !acceptButtonElement || !rejectButtonElement) {
        console.warn(
            'Cookie banner elements are missing. Check your HTML structure.',
        );
        return;
    }

    //Handles the user's choice for accepting or rejecting cookies.
    function handleCookieChoice(buttonType) {
        const isAccepted = buttonType === 'accept';
        setCookie('cookiesAccepted', isAccepted ? 'true' : 'false', 365);

        gsap.to(bannerElement, {
            opacity: 0,
            duration: 1,
            onComplete: () => {
                bannerElement.style.visibility = 'hidden';
            },
        });
    }

    // Check if the cookiesAccepted cookie is set to true
    const cookiesAccepted = getCookie('cookiesAccepted');
    if (cookiesAccepted === 'true') {
        // If cookies are accepted, hide the banner immediately
        bannerElement.style.visibility = 'hidden';
        bannerElement.style.opacity = '0';
        return;
    }

    // Show the banner if cookies have not been accepted
    bannerElement.style.visibility = 'visible';
    bannerElement.style.opacity = '0';
    gsap.to(bannerElement, { opacity: 1, duration: 1 });

    // Handle "Accept" button click
    acceptButtonElement.addEventListener('click', () =>
        handleCookieChoice('accept'),
    );

    // Handle "Reject" button click
    rejectButtonElement.addEventListener('click', () =>
        handleCookieChoice('reject'),
    );
}

export default initCookieBanner;
