import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import mixitup from 'mixitup';

import {
    getUrlParameter,
    updateUrlParameter,
} from '/src/global/js/helpers/url';

/**
 * Handles media filtering using the Mixitup library.
 * Initializes Mixitup with sorting and filtering based on URL parameters
 * and updates URL parameters when filters are applied.
 *
 * @function filterType1
 * @exports filterType1
 * @author Dreamers of Day
 */
function filterType1() {
    // Select the container element for media filtering
    const containerEl = document.querySelector('.js-media-filter');

    // Get the filter and sort parameters from the URL
    const filterLoadParameter = getUrlParameter('filter');
    //const sortLoadParameter = getUrlParameter('sort');

    // Animation timing function
    const transitionEase = 'cubic-bezier(0.42, 0, 0.58, 1)';

    // Initialize mixitup with sorting and filtering options
    const mixer = mixitup(containerEl, {
        load: {
            // Update if we need any other sort option
            //sort: 'published-date:' + sortLoadParameter,
            filter:
                filterLoadParameter && filterLoadParameter !== 'all'
                    ? `.${filterLoadParameter}`
                    : 'all',
        },
    });

    // Set the search param based on the pressed button
    document.querySelectorAll('[data-filter]').forEach((filterBtn) => {
        filterBtn.addEventListener('click', () => {
            // Get the filter value from the button attribute
            const filterRaw = filterBtn.getAttribute('data-filter');
            // Remove the dot from the filter value
            const filterQuery = filterRaw.replace('.', '');
            // Update the URL parameter with the new filter value
            updateUrlParameter('filter', filterQuery);
        });
    });

    // Set the sort param based on the pressed button
    // document.querySelectorAll('[data-sort]').forEach((sortBtn) => {
    //     sortBtn.addEventListener('click', () => {
    //         // Get the sort value from the button attribute
    //         const sortRaw = sortBtn.getAttribute('data-sort');
    //         // Extract the sort direction from the sort value
    //         const sortQuery = sortRaw.split(':')[1] || 'desc';
    //         // Update the URL parameter with the new sort value
    //         updateUrlParameter('sort', sortQuery);
    //     });
    // });

    /**
     * Add smooth appearing effect for images and for the cards container.
     */
    const initImageEffect = () => {
        // Works when user scrolled to the cards container element.
        gsap.registerPlugin(ScrollTrigger);

        // Block component class
        const componentClass = 'o-filter-a-1';

        // Cards container element (hidden by default)
        const itemsContainer = document.querySelector(
            `.${componentClass}__repeater`,
        );
        if (!itemsContainer) {
            return;
        }
        const containerTimeline = gsap.timeline({
            scrollTrigger: {
                trigger: itemsContainer,
                once: true,
            },
            defaults: {
                duration: 1,
                ease: transitionEase,
            },
        });
        containerTimeline.to(
            itemsContainer,
            {
                autoAlpha: 1,
            },
            0,
        );
    };
    // Init this effect when page is loaded.
    initImageEffect();

    /**
     * Apply custom animation for cards on appearing.
     *
     * @param {HTMLElement} el
     */
    function animateCardAppearance(el) {
        const imageWrapper = el.querySelector(
            '.m-card-image-text-a__img-wrapper',
        );
        if (!imageWrapper) {
            return;
        }
        const image = imageWrapper.querySelector('img');
        if (!image) {
            return;
        }
        const title = el.querySelector('.m-card-image-text-a__title-wrapper');
        const subtitle = el.querySelector('.m-card-image-text-a__subtitle');
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: el,
                once: true,
            },
            defaults: {
                duration: 1.5,
                ease: transitionEase,
            },
        });

        tl.to(
            el,
            {
                autoAlpha: 1,
            },
            0,
        )
            .fromTo(
                imageWrapper,
                {
                    filter: 'brightness(150%)',
                    autoAlpha: 0,
                    yPercent: 30,
                },
                {
                    autoAlpha: 1,
                    yPercent: 0,
                    filter: 'brightness(100%)',
                },
                0,
            )
            .fromTo(
                image,
                {
                    scale: 1.2,
                },
                {
                    scale: 1,
                },
                0,
            );

        if (title) {
            tl.fromTo(
                title,
                {
                    yPercent: 20,
                    opacity: 0,
                },
                {
                    yPercent: 0,
                    opacity: 1,
                    duration: 0.6,
                },
                1.0,
            );
        }

        if (subtitle) {
            tl.fromTo(
                subtitle,
                {
                    yPercent: 20,
                    opacity: 0,
                },
                {
                    yPercent: 0,
                    opacity: 1,
                    duration: 0.6,
                },
                1.2,
            );
        }
    }
}

export default filterType1;
