import { CountUp } from 'countup.js';

/**
 * Initializes CountUp animations on all elements with the `data-countup-number` attribute.
 * This function enhances UI by animating numbers from 0 to their final value, with optional
 * prefixes and suffixes. Elements are configured via data attributes, and options are set
 * for scrollSpy and grouping.
 *
 * @author Dreamers of Day
 */
function numberCount() {
    // Selecting all elements intended for number counting animations.
    // Don't initialize CountUp to elements with [data-countup-custom] attribute (use this attribute for custom initialize).
    const countUpElements = document.querySelectorAll(
        '[data-countup-number]:not([data-countup-custom])',
    );

    // Iterating through each element to configure and initiate the count up animation.
    countUpElements.forEach((numberElement) => {
        // Extracting element-specific settings from data attributes or providing defaults.
        const elNumber =
            +numberElement.getAttribute('data-countup-number') || 1000;
        const elDuration =
            +numberElement.getAttribute('data-countup-duration') || 3;
        const elPrefix =
            numberElement.getAttribute('data-countup-prefix') || '';
        const elSuffix =
            numberElement.getAttribute('data-countup-suffix') || '';

        // Defining options for the CountUp instance.
        const options = {
            enableScrollSpy: true, // Enables the scroll spy feature to trigger animations on scroll.
            scrollSpyOnce: true, // Ensures the animation plays only once upon becoming visible.
            useGrouping: false, // Disables digit grouping (e.g., thousands separator).
            duration: elDuration, // Sets the animation duration.
            prefix: elPrefix, // Sets the prefix text for the number.
            suffix: elSuffix, // Sets the suffix text for the number.
        };

        // Initializing CountUp with the selected element, target number, and options.
        new CountUp(numberElement, elNumber, options);
    });
}

export default numberCount;
