import Swiper from 'swiper';

/**
 * Initializes testimonial sliders on the page.
 *
 * This function targets elements with the class
 * `.o-testimonials-c-1__repeater.swiper-container`, initializes Swiper instances
 * for each, and handles responsive behavior by destroying and re-creating
 * the Swiper instances on window resize.
 *
 * @author Dreamers of Day
 */
function testimonialsC() {
    const sliders = document.querySelectorAll(
        '.o-testimonials-c-1__repeater.swiper-container',
    );

    sliders.forEach((slider) => {
        // Initializes the default slider
        let swiper = getSwiperInstance(slider, true);

        // Tracks the previous mobile state to detect changes on resize.
        let prevIsMobile = window.innerWidth < 768;

        window.addEventListener('resize', function () {
            let isMobile = window.innerWidth < 768;

            if (isMobile !== prevIsMobile) {
                // Destroy and reinitialize swiper with updated parameters
                swiper.destroy(true, true); // true for cleanStyles and deleteInstance
                swiper = getSwiperInstance(slider, isMobile);
            } else {
                // Just update if the mobile state hasn't changed
                swiper.update();
            }
            prevIsMobile = isMobile;
        });
    });

    /**
     * Creates and returns a Swiper instance for a given slider element.
     *
     * @param {Element} slider - The slider container element.
     * @param {boolean} autoHeight - Whether to enable auto height for the slider.
     * @returns {Swiper} - A new Swiper instance.
     */
    function getSwiperInstance(slider, autoHeight) {
        // Returns swiper object, with autoHeight based on the param.
        return new Swiper(slider, {
            autoHeight: autoHeight,
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: slider
                    .closest('.o-testimonials-c-1')
                    ?.querySelector('.js-swiper-btn-next'),
                prevEl: slider
                    .closest('.o-testimonials-c-1')
                    ?.querySelector('.js-swiper-btn-prev'),
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    autoHeight: false,
                },
                1280: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                    autoHeight: false,
                },
            },
        });
    }
}

export default testimonialsC;
