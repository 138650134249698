import '@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css';

import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

function initializeSplide(splideElement) {
    const splide = new Splide(splideElement, {
        type: 'loop',
        drag: 'free',
        focus: 'center',
        perPage: 3,
        gap: '30px',
        breakpoints: {
            1023: {
                perPage: 2,
                gap: '10px',
            },
        },
        cover: false,
        arrows: false,
        pagination: false,
        autoScroll: {
            speed: 1,
            autoStart: true,
            pauseOnHover: true,
        },
    });

    splide.mount({ AutoScroll });
}

function mediaListB() {
    const splides = document.querySelectorAll('.splide');
    splides.forEach((splide) => {
        initializeSplide(splide);
    });
}

export default mediaListB;
