import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SplitText from '../../../../../global/js/vendors/gsap-splittext/splittext';

/**
 * Animates the footer elements using GSAP.
 * @returns {void}
 */
function footer() {
    gsap.registerPlugin(ScrollTrigger, SplitText);

    const footer = document.querySelector('.o-footer-1');
    const footerColumns = Array.from(
        document.querySelectorAll('.o-footer-1__column'),
    );
    const footerTitle = document.querySelector('.o-footer-1__agency-name');

    if (footerColumns.length) {
        // Animation for footer columns
        const columnTl = gsap.timeline({
            scrollTrigger: {
                trigger: footer,
                start: 'center bottom',
            },
        });

        // Animation for each footer column
        footerColumns.forEach((column, index) => {
            columnTl.fromTo(
                column,
                { autoAlpha: 0 },
                { duration: 1.4, autoAlpha: 1, ease: 'power4.inOut' },
                index * 0.2,
            );
        });
    }

    if (footerTitle) {
        // Create a GSAP Timeline
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: footer,
                start: 'center bottom',
            },
        });

        // Animation for footerTitle
        tl.fromTo(
            footerTitle,
            { autoAlpha: 0 },
            { duration: 0.3, autoAlpha: 1, ease: 'power4.inOut' },
            '<',
        );

        const split = new SplitText(footerTitle, {
            tag: 'span',
            type: 'chars,words',
            wordsClass: 'leading-tight',
            charsClass: 'leading-tight',
        });

        // Split letter animation
        tl.from(
            split.chars,
            {
                duration: 1.4,
                yPercent: 100,
                ease: 'power4.inOut',
                stagger: {
                    amount: 0.4,
                },
            },
            '<',
        );
    }

    const updateClock = () => {
        const now = new Date();
        const options = { timeZone: 'America/Chicago', hour12: false };
        const texasTime = now.toLocaleTimeString('en-US', options);
        document.getElementById('texas-time').textContent = texasTime;
    };

    // Update the clock every second
    setInterval(updateClock, 1000);

    // Initial call to display the clock immediately
    updateClock();
}

export default footer;
