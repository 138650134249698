/**
 * Hamburger
 *
 * Runs the functionality of clicking the hamburger icon in the top nav.
 */

/**
 * Toggle the hamburger functionality.
 */
export default () => {
    const selector = '.js-hamburger';
    const activeClass = 'is-active';
    const hScrollLockNavActive = 'h-scroll-lock';
    const navActive = 'nav-active';
    const headerActiveClass = 'o-header--hamburger-active';
    const navMenu = '.js-slide-out';
    const navMenuItem = '.menu-item';
    const navMenuActiveClass = 'm-slide-out--active';
    const headerSearchButton = 'o-header-1__button-search';
    const searchBar = 'm-search-bar';
    const plusToggle = '.js-nav-drop';

    const tabbableElements = Array.from(
        document.querySelectorAll(`
        ${navMenu} a[href]:not([disabled]),
        ${navMenu} button:not([disabled]),
        ${navMenu} input:not([disabled]),
        ${navMenu} select:not([disabled]),
        ${navMenu} textarea:not([disabled]),
        ${navMenu} [tabindex]:not([tabindex="-1"]):not([disabled])`),
    ).filter(
        (el) =>
            el.offsetWidth > 0 ||
            el.offsetHeight > 0 ||
            el.getClientRects().length > 0,
    );

    // Get the first element from tabbableElements if it exists
    const firstTabbable =
        tabbableElements.length > 0 ? tabbableElements[0] : null;

    /**
     * Toggle the hamburger menu.
     */
    function toggleMenu() {
        const hamburger = document.querySelector(selector);
        const body = document.querySelector('.js-body');
        const header = document.querySelector('.js-header');
        const menu = document.querySelector(navMenu);
        const searchButton = document.querySelector(`.${headerSearchButton}`);
        const searchInput = document.querySelector(`.${searchBar}`);

        hamburger.classList.toggle(activeClass);
        body.classList.toggle(hScrollLockNavActive);
        body.classList.toggle(navActive);
        header.classList.toggle(headerActiveClass);
        menu.classList.toggle(navMenuActiveClass);
        // searchButton.classList.toggle(`${headerSearchButton}--anim`);

        if (searchInput.classList.contains(`${searchBar}--active`)) {
            const searchButtonSibling =
                hamburger.parentElement.nextElementSibling;
            if (searchButtonSibling) {
                searchButtonSibling
                    .querySelector(`.${headerSearchButton}`)
                    .click();
            }
        }

        // Set or remove tabindex based on the presence of 'navMenuActiveClass'
        if (menu.classList.contains(navMenuActiveClass)) {
            const plusToggles = menu.querySelectorAll(plusToggle);
            plusToggles.forEach((plusToggle) =>
                plusToggle.setAttribute('tabindex', 0),
            ); // Add tabindex
        } else {
            const plusToggles = menu.querySelectorAll(plusToggle);
            plusToggles.forEach((plusToggle) =>
                plusToggle.removeAttribute('tabindex'),
            ); // Remove tabindex
        }

        menu.addEventListener('keydown', handleTabKey);

        toggleNavActiveClass(menu);

        $('.js-header-top').slideToggle(250);
    }

    /**
     * Click event handler for the hamburger icon.
     * @param {Event} e - The click event.
     */
    const hamburgerElement = document.querySelector(selector);
    hamburgerElement.addEventListener('click', (e) => {
        e.preventDefault();
        toggleMenu();
    });

    /**
     * Ensuring slideout nav menu is next element focused after hamburger toggle button
     * @param {Event} e - The click event.
     */

    hamburgerElement.addEventListener('keydown', function (e) {
        if (e.key === 'Tab') {
            const isActive = document
                .querySelector(navMenu)
                .classList.contains(navMenuActiveClass);

            if (isActive) {
                e.preventDefault();

                if (firstTabbable) {
                    firstTabbable.focus(); // Focus on the first tabbable element
                }
            }
        }
    });

    /**
     * Ensuring focus shifts back to hamburger toggle button when going back
     * @param {Event} e - The click event.
     */

    if (firstTabbable) {
        firstTabbable.addEventListener('keydown', function (e) {
            if (e.shiftKey && e.key === 'Tab') {
                const isActive = document
                    .querySelector(navMenu)
                    .classList.contains(navMenuActiveClass);

                if (isActive && document.activeElement === firstTabbable) {
                    e.preventDefault();
                    hamburgerElement.focus(); // Focus the element matched by selector
                }
            }
        });
    }

    /**
     * Mouseup event handler to close the hamburger menu when clicking outside.
     * @param {Event} e - The mouseup event.
     */
    document.addEventListener('mouseup', (e) => {
        const isHamburgerActive =
            hamburgerElement.classList.contains(activeClass);
        const isClickOutsideMenu =
            !hamburgerElement.isSameNode(e.target) &&
            !hamburgerElement.contains(e.target);
        const isNotNavMenu = !document
            .querySelector(navMenu)
            .contains(e.target);

        if (isHamburgerActive && isClickOutsideMenu && isNotNavMenu) {
            toggleMenu();
        }
    });

    /**
     * Resize event handler to close the hamburger menu when the window width exceeds a certain threshold.
     */
    window.addEventListener('resize', () => {
        const windowWidth = window.innerWidth;
        const isHamburgerActive =
            hamburgerElement.classList.contains(activeClass);

        if (windowWidth >= 768 && isHamburgerActive) {
            toggleMenu();
        }
    });

    /**
     * Handles key events to toggle the '.tab_key' class on a navMenuElements.
     *
     * @param {HTMLElement} menuElement - The menu element.
     */
    function handleNavItemFocus(menuElement) {
        let tabPressed = false;
        menuElement.addEventListener('keyup', (event) => {
            if (event.key === 'Tab' && !tabPressed) {
                menuElement.classList.add('tab_key');
                tabPressed = true;
            }
        });
        menuElement.addEventListener('keydown', (event) => {
            if (event.key === 'Tab' && tabPressed) {
                menuElement.classList.remove('tab_key');
                tabPressed = false;
            }
        });
    }

    /**
     * Toggles the '.tab_key' class on navMenuElements within a menu on key events.
     *
     * @param {HTMLElement} navMenuElement - The menu element.
     */
    function toggleNavActiveClass(navMenuElement) {
        const menuElements = navMenuElement.querySelectorAll(
            `${navMenu} a[href]:not([disabled]), ${navMenu} button:not([disabled]),
             ${navMenu} input:not([disabled]), ${navMenu} select:not([disabled]),
             ${navMenu} textarea:not([disabled]), ${navMenu} [tabindex]:not([tabindex="-1"]):not([disabled]),
             ${navMenu} ${plusToggle}`,
        );

        if (menuElements) {
            menuElements.forEach((menuElement) => {
                handleNavItemFocus(menuElement);
            });
        }
    }

    /**
     * Tracks the last tabbed menu item element or submenu plus toggler and ensures that when either of them is tabbed, focus comes back to the hamburger element which toggles the slide-out menu.
     */
    function handleTabKey(e) {
        const tabKey = e.key === 'Tab';
        const shiftKey = e.shiftKey;

        // Filter menu items to include only those that are visible
        const menuItemsMainLevel = document.querySelectorAll(
            `${navMenu} li.m-nav__item--level-0:not(.hide-on-hamburger)`,
        );
        const menuItems = document.querySelectorAll(
            `${navMenu} ${navMenuItem}:not(.hide-on-hamburger)`,
        );
        const plusToggles = document.querySelectorAll(
            `${navMenu} ${plusToggle}:not(.hide-on-hamburger)`,
        );

        // Grabbing the last items
        const lastMainLevelItem =
            menuItemsMainLevel[menuItemsMainLevel.length - 1];
        const lastMenuItem = menuItems[menuItems.length - 1];
        const lastPlusToggle = plusToggles[plusToggles.length - 1];

        // Check if the active element is inside the last menuItemsMainLevel list item
        const isInsideLastMainLevelItem =
            lastMainLevelItem &&
            lastMainLevelItem.contains(document.activeElement);

        // Giving focus to hamburger after the last item
        if (tabKey && !shiftKey && isInsideLastMainLevelItem) {
            // Actions to be performed when inside the lastMainLevelItem
            if (
                document.activeElement === lastMenuItem &&
                !lastPlusToggle.classList.contains('tab_key')
            ) {
                e.preventDefault();
                document.querySelector(selector).focus(); // Focuses the hamburger element
            }

            if (
                document.activeElement === lastPlusToggle &&
                !lastPlusToggle.classList.contains('is-active')
            ) {
                e.preventDefault();
                document.querySelector(selector).focus(); // Focuses the hamburger element
            }
        }
    }
};
