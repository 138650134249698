/**
 * @function updateUrlParameter
 * @description Updates a URL parameter and modifies the browser's URL without reloading the page.
 * @param {string} key - The name of the parameter to update.
 * @param {string} value - The value to set for the parameter.
 * @author Dreamers of Day
 */
export function updateUrlParameter(key, value) {
    // Create a URL object from the current location
    const url = new URL(window.location);

    // Update the search parameter
    url.searchParams.set(key, value);

    // Update the browser's URL without reloading the page
    window.history.pushState({}, '', url);
}

/**
 * @function getUrlParameter
 * @description Retrieves the value of a specified URL parameter.
 * @param {string} key - The name of the parameter to retrieve.
 * @returns {string|null} The value of the specified parameter, or null if not found.
 * @author Dreamers of Day
 */
export function getUrlParameter(key) {
    // Get the query string part of the URL
    const queryString = window.location.search;

    // Parse the query string parameters
    const urlParams = new URLSearchParams(queryString);

    // Get the value of the specified parameter
    return urlParams.get(key);
}
