import Sticky from 'sticky-js';

/**
 * Initializes a sticky element on the page using the Sticky-js library.
 *
 * This function selects elements with the `data-sticky` attribute and applies a sticky behavior
 *
 * @author Dreamers of Day
 */
function stickyInit() {
    const hooks = {
        stickySelector: '[data-sticky]',
    };

    const defaultOptions = {
        marginTop: 150,
    };

    // Attempt to create a new sticky instance and catch any errors that might occur.
    try {
        new Sticky(hooks.stickySelector, defaultOptions);
    } catch (error) {
        console.error('Failed to initialize sticky elements:', error);
    }
}

export default stickyInit;
