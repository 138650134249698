import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/**
 * Toggles section color themes on scroll for elements with
 * the 'h-section-color-theme-toggler' class. This function utilizes
 * GSAP and ScrollTrigger to apply smooth color transitions.
 *
 * @namespace sectionColorThemeToggler
 * @memberof Dreamers of Day
 */
const sectionColorThemeToggler = () => {
    ScrollTrigger.refresh();

    setTimeout(() => {
        // Register ScrollTrigger plugin with GSAP
        gsap.registerPlugin(ScrollTrigger);

        /**
         * Retrieves the computed CSS variable value for a given CSS variable name.
         *
         * @param {string} variable - The CSS variable to retrieve.
         * @returns {string} The trimmed value of the specified CSS variable.
         */
        const getCSSVariable = (variable) =>
            getComputedStyle(document.documentElement)
                .getPropertyValue(variable)
                .trim();

        // Define colors for light mode using CSS variables
        const lightModeColors = {
            '--color-pretitle-light': getCSSVariable('--color-pretitle-light'),
            '--color-heading-light': getCSSVariable('--color-heading-light'),
            '--color-body-light': getCSSVariable('--color-body-light'),
            '--color-background-light-color': getCSSVariable(
                '--color-background-light-color',
            ),
            '--color-border-light-color': getCSSVariable(
                '--color-border-light-color',
            ),
            '--color-button-light': getCSSVariable('--color-button-light'),
            '--color-dropdown-accent-color-light': getCSSVariable(
                '--color-dropdown-accent-color-light',
            ),
            '--color-pretitle-dark': getCSSVariable('--color-pretitle-dark'),
            '--color-heading-dark': getCSSVariable('--color-heading-dark'),
            '--color-body-dark': getCSSVariable('--color-body-dark'),
            '--color-background-dark-color': getCSSVariable(
                '--color-background-dark-color',
            ),
            '--color-border-dark-color': getCSSVariable(
                '--color-border-dark-color',
            ),
            '--color-button-dark': getCSSVariable('--color-button-dark'),
            '--color-dropdown-accent-color-dark': getCSSVariable(
                '--color-dropdown-accent-color-dark',
            ),
        };

        // Generate dark mode colors by toggling light and dark suffixes in keys
        const darkModeColors = Object.keys(lightModeColors).reduce(
            (acc, key) => {
                acc[key] = key.includes('light')
                    ? lightModeColors[key.replace('light', 'dark')]
                    : lightModeColors[key.replace('dark', 'light')];
                return acc;
            },
            {},
        );

        // Select all sections that toggle color themes
        const themeSections = document.querySelectorAll(
            '.h-section-color-theme-toggler',
        );
        const consecutiveSections = []; // Groups of consecutive theme sections
        let currentGroup = []; // Temp array for currently grouped consecutive sections

        // Group consecutive sections together
        themeSections.forEach((section, index) => {
            if (
                index > 0 &&
                section.previousElementSibling?.classList.contains(
                    'h-section-color-theme-toggler',
                )
            ) {
                currentGroup.push(section);
            } else {
                if (currentGroup.length) {
                    consecutiveSections.push([...currentGroup]);
                }
                currentGroup = [section];
            }
        });
        if (currentGroup.length) {
            consecutiveSections.push([...currentGroup]);
        }

        // Apply GSAP animation to each group of consecutive sections
        consecutiveSections.forEach((group) => {
            const firstSectionInGroup = group[0];
            gsap.fromTo(
                document.documentElement,
                { duration: 0.4, ...lightModeColors }, // Starting colors (light mode)
                {
                    ...darkModeColors, // Target colors (dark mode)
                    immediateRender: false, // Avoid initial rendering
                    scrollTrigger: {
                        trigger: firstSectionInGroup, // Trigger animation on group scroll
                        start: 'top 30%', // Start position relative to viewport
                        toggleActions: 'play none play reverse', // Play/reverse animation
                    },
                    ease: 'power2.inOut', // Animation easing for smooth transition
                },
            );
        });
    }, 100);
    // Refresh ScrollTrigger instances on page load to ensure proper setup
    //window.addEventListener('load', () => {
    //ScrollTrigger.refresh();
    //});
};

export default sectionColorThemeToggler;
