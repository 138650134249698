import gsap from 'gsap';
import mixitup from 'mixitup';

/**
 * Manages media filtering functionality for a service page using the Mixitup library.
 *
 * - Initializes a Mixitup instance to control filtering and animations.
 * - Updates the active filter button and displayed category title based on user interaction.
 * - Synchronizes a visual glider indicator with the active button's position.
 *
 * @function servicesA1
 * @exports servicesA1
 * @module servicesA1
 * @see {@link https://www.kunkalabs.com/mixitup/}
 * @requires mixitup
 * @example
 * import servicesA1 from './path-to-servicesA1';
 * servicesA1();
 *
 * @author Dreamers of Day
 */
function blockInit(block) {
    // Cache DOM elements for performance and readability
    const container = block.querySelector('.services-container');
    const controls = block.querySelector('.controls');
    const currentCategoryTitle = block.querySelector(
        '.js-current-category-title',
    );
    const postsCards = Array.from(container.querySelectorAll('.js-post-card'));
    const postsContentContainer = container.querySelector(
        '.js-posts-content-container',
    );
    const buttons = Array.from(
        block.querySelectorAll('.o-services-a-1__filter-button'),
    );
    const glider = block.querySelector('.o-services-a-1__glider');
    const header = document.querySelector('.js-header');
    const wpAdminBar = document.getElementById('wpadminbar');

    // Current active post ID
    let currentPostID = null;

    // Default filter settings
    const defaultTermButton = controls.querySelector('.filter');
    const defaultFilter =
        defaultTermButton?.getAttribute('data-filter') || 'none';
    const defaultTermID = defaultTermButton?.dataset?.termId || null;

    // Animation properties
    const animDuration = 150; // In milliseconds

    // Initialize Mixitup instances
    const mixer = mixitup(container, {
        animation: { duration: animDuration, effects: 'fade', easing: 'ease' },
        controls: { enable: false },
        load: { filter: defaultFilter },
    });

    const postContentCardsMiu = mixitup(postsContentContainer, {
        animation: { duration: animDuration, effects: 'fade', easing: 'ease' },
        selectors: { target: '.js-post-content-card' },
        controls: { enable: false },
        load: { filter: 'none' },
    });

    // Set default glider position
    updateGliderPosition();

    // Set initial active post by default term ID
    updateActivePostByTermID(defaultTermID);

    /**
     * Handles filter button clicks, updating the UI and filtering posts.
     *
     * @param {Event} event - The click event on a filter button.
     */
    function handleFilterClick(event) {
        const target = event.target.closest('.filter');
        if (!target) {
            return;
        }

        const filter = target.getAttribute('data-filter');
        const termID = target.dataset?.termId || null;

        // Update active button state
        controls
            .querySelectorAll('.filter')
            .forEach((btn) =>
                btn.classList.remove('o-services-a-1__filter-button--active'),
            );
        target.classList.add('o-services-a-1__filter-button--active');

        // Update the current category title
        if (currentCategoryTitle) {
            // Fade out the title
            gsap.to(currentCategoryTitle, {
                opacity: 0,
                duration: animDuration / 1000,
                onComplete: () => {
                    // Update the text content
                    currentCategoryTitle.textContent =
                        target.textContent.trim();

                    // Fade it back in
                    gsap.to(currentCategoryTitle, {
                        opacity: 1,
                        duration: 0.3,
                    });
                },
            });
        }

        // Apply the filter
        mixer.filter('none').then(() => mixer.filter(filter));
        // Update Glider position
        updateGliderPosition();
        // Update the posts content block as well
        updateActivePostByTermID(termID);
    }

    /**
     * Updates the position of the glider to match the active filter button.
     */
    function updateGliderPosition() {
        const activeButton = buttons.find((button) =>
            button.classList.contains('o-services-a-1__filter-button--active'),
        );
        if (activeButton) {
            const index = buttons.indexOf(activeButton);
            glider.style.transform = `translateX(${index * 100}%)`;
        }
    }

    /**
     * Highlights the active post card and updates the content block visibility.
     *
     * @param {string|null} termID - The term ID to filter by.
     */
    function updateActivePostByTermID(termID) {
        const activePostCard = postsCards.find((card) => {
            let termIds = (card.dataset?.termIds ?? '').split(',');
            return termIds.includes(termID);
        });
        currentPostID = activePostCard?.dataset?.postId || null;

        highlightActivePostCard(activePostCard);
        updatePostsContentBlock();
    }

    /**
     * Highlights the active post card by its post ID.
     *
     * @param {string|null} postID - The post ID to filter by.
     */
    function updateActivePostByPostID(postID) {
        if (!postID) {
            currentPostID = null;
            return;
        }

        const activePostCard = postsCards.find(
            (card) => Number(card.dataset.postId) === Number(postID),
        );
        currentPostID = postID;

        highlightActivePostCard(activePostCard);
        updatePostsContentBlock();
    }

    /**
     * Toggles the active state for the given post card.
     *
     * @param {HTMLElement|null} postCard - The post card to highlight.
     */
    function highlightActivePostCard(postCard) {
        postsCards.forEach((card) =>
            card.classList.toggle('active', card === postCard),
        );
    }

    /**
     * Filters the content block to show the card matching the active post ID.
     */
    function updatePostsContentBlock() {
        if (currentPostID) {
            postContentCardsMiu
                .filter('none')
                .then(() =>
                    postContentCardsMiu.filter(
                        `[data-post-id="${currentPostID}"]`,
                    ),
                );
        } else {
            postContentCardsMiu.filter('none');
        }
    }

    // Categories filters clicks
    controls.addEventListener('click', handleFilterClick);

    // Posts cards clicks
    postsCards.forEach((postCard) => {
        postCard.addEventListener('click', (event) => {
            event.preventDefault();
            updateActivePostByPostID(postCard.dataset?.postId || null);
            scrollToContent();
        });
    });

    /**
     * Scrolls to posts content container.
     */
    function scrollToContent() {
        let element = window.innerWidth >= 1024 ? block : postsContentContainer;
        const computedStyle = window.getComputedStyle(element);
        const paddingTop = parseFloat(computedStyle.paddingTop) || 0;
        // Calculate the scroll position considering the padding
        const targetPosition =
            element.getBoundingClientRect().top +
            window.scrollY -
            getTopOffset() +
            paddingTop;
        window.scrollTo({
            top: targetPosition,
            behavior: 'smooth',
        });
    }

    /**
     * Returns a sum of heights for the sticky top elements.
     *
     * @return {number}
     */
    function getTopOffset() {
        let offset = 0;
        if (header) {
            offset += header.offsetHeight;
        }
        if (wpAdminBar) {
            offset += wpAdminBar.offsetHeight;
        }
        return offset;
    }
}

export default function servicesA1() {
    const blocks = document.querySelectorAll(`[data-module='servicesA1']`);
    blocks.forEach((block) => blockInit(block));
}
